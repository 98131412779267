<template>
  <div class="reports-toolbar custom--toolbar--btns px-4 pb-0">
    <div class="mr-2">
      <v-row class="mx-0 my-0 align-items-center">
        <div class="mr-4 mb-3">
          <v-autocomplete
            v-model="filter.card.corp_card"
            :items="corCardsList"
            item-text="name"
            dense
            item-value="id"
            outlined
            return-object
            hide-details
            clearable
            label="Karta raqami"
            style="width: 400px"
          />
        </div>

        <div class="mr-4 mb-3">
          <v-menu
            ref="yearMenuRef"
            v-model="menu.year"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Hisobot yili"
                :nudge-right="40"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
                hide-details
                clearable
                :value="
                  filter.date.yymm.year &&
                  `${filter.date.yymm.year}-01-01` | yearOnly
                "
                @input="onInput(arguments[0], 'date.yymm.year')"
              />
            </template>
            <v-date-picker
              ref="yearPickerRef"
              :value="`${filter.date.yymm.year || currentYear}-01-01`"
              locale="ru"
              type="year"
              :max="new Date().toISOString().substr(0, 10)"
              min="2010-01-01"
              @click:year="save(arguments[0], 'date.yymm.year')"
            />
          </v-menu>
        </div>

        <div class="mr-4 mb-3">
          <v-menu
            ref="yearMonthRef"
            v-model="menu.month"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="ml-2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Oy"
                :nudge-right="40"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
                :value="
                  filter.date.yymm.month &&
                  `${filter.date.yymm.year || currentYear}-${
                    filter.date.yymm.month || '01'
                  }-01` | monthOnly
                "
                hide-details
                clearable
                @input="onInput(arguments[0], 'date.yymm.month')"
              />
            </template>
            <v-date-picker
              ref="monthPickerRef"
              :value="`${filter.date.yymm.year || currentYear}-${
                filter.date.yymm.month || '01'
              }-01`"
              locale="ru"
              no-title
              type="month"
              min="2010-01-01"
              no-date-picker-header
              @click:month="save(+arguments[0].slice(5), 'date.yymm.month')"
            />
          </v-menu>
        </div>
      </v-row>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="mr-1">
        <div class="d-flex align-items-center">
          <el-button
            style="
              min-width: 5rem;
              height: 2.5rem;
              background-color: #ecf5ff;
              color: #409eff;
            "
            @click="onFilterSubmit"
            >Izlash</el-button
          >
        </div>
      </div>

      <toExcel
        @excel-download-emit="$emit('excel-download-emit', arguments[0])"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
// import FilterDialog from './filters'
import toExcel from './toExcel'

import { takeFilledKeyValue } from '@/utils'
import { monthOnly, yearOnly } from '@/utils/filters/dateFormats.js'

import set from 'lodash/set'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

function filterDefaultProps() {
  return {
    card: {
      corp_card: ''
    },

    date: {
      yymm: {
        year: +this.$route.query.year || currentYear,
        month: +this.$route.query.month || currentMonth
      },

      range: {
        from_date: '',
        to_date: ''
      }
    }
  }
}

export default {
  components: {
    // FilterDialog,
    toExcel
  },

  data() {
    return {
      currentYear,

      menu: {
        year: false,
        month: false
      },

      filter: {
        ...this.filterDefaultProps()
      }
    }
  },

  watch: {
    'menu.year': {
      handler(val) {
        if (val)
          setTimeout(() => (this.$refs.yearPickerRef.activePicker = 'YEAR'))
      }
    },
    'menu.month': {
      handler(val) {
        if (val)
          setTimeout(() => (this.$refs.monthPickerRef.activePicker = 'MONTH'))
      }
    }
  },

  filters: {
    monthOnly,
    yearOnly
  },

  methods: {
    filterDefaultProps,
    takeFilledKeyValue,

    ...mapActions(['getAllCorCards']),
    ...mapMutations(['SET_REPORTS']),

    onFilterSubmit() {
      // this.$emit('filter:corp-card', this.filter.card)

      this.SET_REPORTS({})

      this.$emit(
        'filter:custom_dates',
        takeFilledKeyValue({
          ...this.filter.date.yymm,
          corp_card_id:
            this.filter.card.corp_card && this.filter.card.corp_card.id
        })
      )
    },

    // eslint-disable-next-line no-unused-vars
    onInput(t = null, arg) {
      set(this.filter, arg, t)

      // for emit filter props to parent
      this.onSearchByDate()
    },

    save(dateTimeValue, datePickerPropKeyList) {
      set(this.filter, datePickerPropKeyList, dateTimeValue)
    },

    onSearchByDate() {
      const props = takeFilledKeyValue(this.filter.date.yymm)

      // console.log('props', props)
      this.$emit('filter:dates', props)
    }
  },

  computed: {
    corCardsList() {
      const data = this.$store.state.requests.AllCorCards
      if (data.length !== 0) {
        data.forEach((element) => {
          element.name =
            element.owner_name +
            ' - ' +
            element.number_16_digit
              .toString()
              .replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
        })
      }
      return data
    }
  },

  created() {
    this.getAllCorCards().then(() => {
      if (this.$route.query.corp_card_id) {
        const f = this.corCardsList.find(
          (item) => item.id == this.$route.query.corp_card_id
        )

        if (f) {
          this.filter.card.corp_card = f
        }
      }
    })
  }
}
</script>

<style>
.reports-toolbar {
  /* border: 1px solid #01534f; */
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  height: unset !important;
}

.custom--toolbar--btns .el-button {
  /* padding: 0px !important;
  padding: 0 0.5rem !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border: 1px solid transparent;
}

.reports-toolbar > i,
.el-icon-search::before {
  color: white;
}
</style>
