import monthNames from '@/constants/js/month.names.js'

function yearOnly(val) {
  return (val && new Date(val).getFullYear()) || ''
}
function monthOnly(val) {
  return (val && monthNames[new Date(val).getMonth()]) || ''
}

export { yearOnly, monthOnly }
